import React from "react"
import Footer from "../../components/footer"
import "../../styles/byTheNumber.less"
import SEO from "../../components/SEO"
import { SolutionTopBanner } from "../../components/block"
const loadText = require("src/utils").loadText
const { india } = loadText("by-the-numbers")
import {
  MarketOverview,
  Sub,
  ConnectionType,
  Percentage,
  Average,
} from "./china"

export default function Home() {
  return (
    <div className="byTheNumber">
      <SEO {...india.seo} />
      <div className="indiaBy">
        <SolutionTopBanner
          {...india.topBanner}
          bgColor="linear-gradient(180deg,#F2FDFF 100%, #E0F9FF 100%)"
          bgPic={"/byTheNumbers/india/topBanner-bg.svg"}
          bannerPic={{
            src: `/byTheNumbers/india/topBanner-bg-m.png`,
            style: {
              backgroundPosition: "top center",
            },
          }}
          height={590}
          pic={{
            src: "/byTheNumbers/india/topBanner-pic.png",
            style: {
              right: 0,
              bottom: "-47px",
              position: "absolute",
              width: "642px",
            },
          }}
          picM={{
            src: "/byTheNumbers/india/topBanner-pic-india-m.png",
            style: {
              right: 0,
              bottom: "-47px",
              position: "absolute",
            },
          }}
        />
      </div>
      <div className="text-center">
        <MarketOverview src="/byTheNumbers/india/market-overview.png" />
        <Sub src="/byTheNumbers/india/subscriber.jpg" />
        <ConnectionType
          leftSrc="/byTheNumbers/india/connectionType-1.png"
          rightSrc="/byTheNumbers/india/connectionType-2.png"
          bgColor="linear-gradient(180deg,#F5F6FF 0%,#E7EAFF 100%)"
          bgPic="/byTheNumbers/india/connectionType-bg.svg"
        />
        <div className="average-percentage">
          <Average
            src1="/byTheNumbers/india/daily.jpg"
            src2="/byTheNumbers/india/share.png"
            bgColor="linear-gradient(180deg,#FFFFFF 0%,#E7EAFF 100%)"
          />
          <Percentage
            src1="/byTheNumbers/india/gamePercentage.jpg"
            src2Left="/byTheNumbers/india/mobile-left.jpg"
            src2Right="/byTheNumbers/india/mobile-right.jpg"
            src2Group="/byTheNumbers/india/srcGroup-m.svg"
            srcGroup={[
              "On any device",
              "Smart phone",
              "PC",
              "Console",
              "Tablet",
            ].map(key => ({
              key,
              src: `/byTheNumbers/india/${key}.jpg`,
            }))}
            srcGroupM={"/byTheNumbers/india/srcGroup-m.png"}
          />
        </div>
      </div>
      <Footer />
    </div>
  )
}
